import { render, staticRenderFns } from "./PersonInfo.vue?vue&type=template&id=7d8e899c&scoped=true"
import script from "./PersonInfo.vue?vue&type=script&lang=js"
export * from "./PersonInfo.vue?vue&type=script&lang=js"
import style0 from "./PersonInfo.vue?vue&type=style&index=0&id=7d8e899c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d8e899c",
  null
  
)

export default component.exports