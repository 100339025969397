<template>
  <van-popup
    v-model="showPopup"
    class="service-pop"
    position="right"
    closeable
    style="width: 100%; height: 100%"
  >
    <div class="fill-in-wrap">
      <div class="content">
        <div class="input-wrap">
          <van-field label="身份证" required input-align="right" />
        </div>
        <div class="input-wrap">
          <Upload
            v-model="license"
            previewVali="mobile"
            :maxCount="1"
            :isDefaultAccept="false"
            accept=".jpg"
            placeholder="请上传行驶证"
            @upload="licenseOcr"
          />
        </div>
        <Form
          ref="formObj"
          :defaultForm.sync="form"
          :defaultText.sync="text"
          :fields="fields"
        />
      </div>
      <div class="btns">
        <van-button size="small" @click="showPopup = false">取消</van-button>
        <van-button type="info" size="small" @click="saveFn">确定</van-button>
      </div>
    </div>
  </van-popup>
</template>
<script>
import { ocrLicense, licenseUpdate, licenseInfo } from "@/api/apply";
export default {
  components: {
    Form: () => import("@/components/Form/Index"),
    Upload: () => import("@/components/Upload.vue")
  },
  data() {
    return {
      tips: "等待上传身份证人像面",
      expiry: true,
      license: [],
      form: {},
      text: {}
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    prePlatOrderNo: {
      type: String,
      default: ""
    }
  },
  computed: {
    showPopup: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      }
    },
    fields() {
      return [
        {
          label: "车牌",
          key: "carPlateNum",
          required: true,
          type: "text",
          placeholder: "请上传行驶证"
        },
        {
          label: "车架号",
          key: "vin",
          type: "text",
          placeholder: "请上传行驶证",
          required: true
        },
        {
          label: "所有人",
          key: "ownerName",
          required: true,
          type: "text",
          placeholder: "请上传行驶证"
        },
        {
          label: "发动机号",
          key: "engineNum",
          required: true,
          type: "text",
          placeholder: "请上传行驶证"
        },
        {
          label: "品牌型号",
          key: "carModel",
          required: true,
          type: "text",
          placeholder: "请上传行驶证"
        },
        {
          label: "车辆类型",
          key: "vehicleType",
          required: true,
          type: "text",
          placeholder: "请上传行驶证"
        },
        {
          label: "使用性质",
          key: "useCharacter",
          required: true,
          type: "text",
          placeholder: "请上传行驶证"
        },
        {
          label: "注册日期",
          key: "registerDate",
          required: true,
          placeholder: "请上传行驶证",
          type: "date"
        },
        {
          label: "发证日期",
          key: "issueDate",
          required: true,
          placeholder: "请上传行驶证",
          type: "date"
        },
        {
          label: "签发机关",
          key: "issueAuthority",
          required: true,
          type: "text",
          placeholder: "请上传行驶证"
        },
        {
          label: "住址",
          key: "address",
          required: true,
          type: "text",
          placeholder: "请上传行驶证"
        }
      ];
    }
  },
  watch: {
    showPopup(v) {
      if (!v) {
        this.form = {};
        this.license = [];
        // this.tips = "等待上传身份证人像面";
      } else {
        this.getLicenseInfo();
      }
    }
  },
  methods: {
    async getLicenseInfo() {
      try {
        if (!this.prePlatOrderNo) throw "预进件编号错误";
        const { data } = await licenseInfo(this.prePlatOrderNo);
        if (data) {
          this.$set(this, "form", data);
          this.$set(this, "license", [
            {
              name: "行驶证",
              fileUrl: data?.imageUrl,
              url: data?.imageUrl
            }
          ]);
        }
      } catch (error) {
        this.$errMsg(error);
      }
    },
    async licenseOcr(item) {
      try {
        const prePlatOrderNo = this.prePlatOrderNo;
        this.$toast.loading({
          message: "图片上传识别中...",
          forbidClick: true,
          loadingType: "circular",
          duration: 0
        });
        const params = {
          prePlatOrderNo,
          imageUrl: item.url,
          side: "face"
        };
        const { data } = await ocrLicense(params);

        this.$set(this, "form", data);
        this.$toast.clear();
      } catch (error) {
        this.tips = error;
        this.$errMsg(error);
      }
    },
    async saveFn() {
      try {
        await this.$refs.formObj.validate();
        const params = {
          ...this.form,
          prePlatOrderNo: this.prePlatOrderNo
        };
        await licenseUpdate(params);
        this.$emit("success", this.form);
        this.showPopup = false;
      } catch (error) {
        this.$errMsg(error);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.fill-in-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  .tips {
    padding: 12px 24px;
    line-height: 40px;
    font-size: 24px;
    color: #f5a227;
    background: #fff1dc;
    min-height: 48px;
    box-shadow: 0 5px 5px #72634c15;
    margin-bottom: 5px;
  }
  .head {
    height: 96px;
    text-align: center;
    line-height: 96px;
    font-size: 32px;
    font-weight: 600;
    color: #333;
    border-bottom: 1px solid #efefef;
  }
  .content {
    flex: 1;
    overflow-y: auto;

    .input-wrap {
      width: 100%;
      min-height: 96px;
      display: flex;
      align-items: center;
      overflow: hidden;
      padding: 0 32px;
      :deep(.van-field__label) {
        width: 7em;
      }
      :deep(.upload-ctn) {
        width: 50%;
        position: relative;
        .icon-delete {
          right: 0;
          top: 0;
          font-size: 32px;
        }
        &:first-child {
          margin-right: 16px;
        }
        &:last-child {
          margin-left: 16px;
        }
        .van-loading {
          position: absolute;
          left: 0;
          height: 100%;
          display: flex;
          top: 0;
          flex-direction: column;
          justify-content: center;
        }

        & > div {
          width: 100%;
          *:not(i) {
            display: block;
            width: calc((100vw - 96px) / 2);
          }
          .van-uploader__upload {
            display: flex;
          }
        }
      }
    }
    .color-r {
      color: red;
    }
    .color-blue {
      color: rgb(24, 144, 255);
    }
  }
  .btns {
    border-top: 1px solid #efefef;
    height: 112px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 -5px 10px #efefef;
    .van-button {
      width: 45%;
      height: 75%;
    }
  }
}
</style>
